@import  '../../shared/styles/variable.scss';

.header {
    position: fixed;
    top: 0;
    width: 100vw;
    color: $color-light-white;
    z-index: 999;
}

#logo-title {
    text-decoration: none;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    color :$color-light-white;
}



.navbar {
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;

    @media screen and (max-width: 600px) {
        justify-content: center;
        flex-wrap: wrap;
    }

}

.navbar li a {
    color:$color-light-white;
    font-size: 1rem;
    text-decoration:none;
  }



.navbar li a:after {
    display: block;
    content:'';
    background:$color-light-white;
    height: 0.175rem;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin-top: 0.25rem;
    -webkit-transform:scale(0);
    transform:scale(0);
}




.navbar li a.section-active:after, .navbar li a.not-touch-screen:hover:after {
    -webkit-transform:scale(1);
    transform:scale(1);
}





#nav {
    padding-inline-start: 0;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;

}


#nav li {
    margin-left: 1.5rem;
}
