$color-steel-blue: #4682B4;
$color-light-white: #F5F5F5;
$color-dim: #6c6d6d;
$color-light-grey: #8a9196;
$color-grey-bleu: #36454f;


$color-blue: #2c98f0;
$color-duck-blue: #2fa499;
$color-dark-blue: #4054b2;
$color-dark-green:#345B4D;
$color-purple: #a84cb8;


$background-dark-gey: #353535;

$color-font-light-grey: #A9A9A9;

@keyframes scale-display {
	0% {
		opacity: 0;
		transform: scale(0);
    -webkit-transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
    -webkit-transform: scale(1);
	}
}

@keyframes scale-display--reversed {
	0% {
		display: inline-flex;
		opacity: 1;
		transform: scale(1);
		-webkit-transform: scale(1);
	}
	99% {
		display: inline-flex;
		opacity: 0;
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	100% {
		display: none;
		opacity: 0;
		transform: scale(0);
		-webkit-transform: scale(0);
	}
}
