@import  '../../shared/styles/variable.scss';

.social-side-bar {
    padding: 0;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 199;
    margin-right: 0.2rem;
}

.social-icons-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.social-icon-link {
    display: flex;
    align-items: center;
    height: 3rem;


}

#mail-icon:hover {
    cursor: pointer;
}

#mail-icon.not-touch-screen:hover {
    color: #1e1e1e;
}

#mail-hidden-checkbox {
    display: none;

}

#mail-hidden-checkbox:checked ~ dialog {
    display: block;
}

#mail-dialog {
    font-family: monospace;
    position: fixed;
    left: -20rem;
    z-index: 300;

    label {
        background: $background-dark-gey;
        color: $color-light-white;
        padding: .5rem 0.5rem;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
        }
    }
}

.action-button {
    display: flex;
    align-items: center; 
    justify-content: space-between;
}



.copy-icon {
    font-size: 1.5rem;
    &:hover {
        cursor: pointer;
    }
}

.not-touch-screen:hover .social-icon {
    color: #1e1e1e;
}




.social-icon {
    font-size: 2rem;
    color:  $color-light-white;
}

#instagram-icon {
    transition-property: left, top, position;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.invisible {
	display: none;
}

.hidden {
    visibility: hidden;
}


