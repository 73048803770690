@import  './shared/styles/variable.scss';


.app {
  text-align: center;
}


.app:after {
  content: '';
  opacity: 0.75;
  background: #000000; /* Old browsers */
  background: -moz-linear-gradient(top, #000000 0%, #484848 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #000000 0%,#484848 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #000000 0%,#484848 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#484848',GradientType=0 ); /* IE6-9 */
  position:fixed;
  left:0;
  top:0;
  right:0;
  bottom: 0;
  z-index: -50 ;
}

#background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -999;
}

h1 {
  font-size: 3rem;
}

section {
  height: calc(100vh - 2rem);
  max-width: calc(100% - 3rem);
  color: $color-light-white;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top : 2rem;

  @media screen and (max-width: 600px) {
    margin-top : 3.5rem;
    height: unset;
  }

  @media screen and (max-width: 426px) {
    height: unset;
  }

  @media screen and (max-height: 650px) {
    height: unset;
  }


}


