@import  '../../shared/styles/variable.scss';

.home {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 2rem);

    @media screen and (max-width: 600px) {
        height: calc(90vh - 3.5rem);
    }
    
      @media screen and (max-width: 426px) {
        height: calc(90vh - 3.5rem);
      }
    
      @media screen and (max-height: 650px) {
        height: calc(90vh - 3.5rem);
      }


}

.home>div:last-child {
    margin-top: auto;
    margin-bottom: 1rem;
    width: 100%;
}

.pusher {
    margin-bottom: auto;
}

.name {
    margin-bottom: 0;
}

.title {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.looping-position {
    font-size: 2rem;
}

.scroll-down {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link:hover {
    cursor: pointer;
}

.home-footer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

#home-footer-left {
    font-size: 0.75rem;
    text-align: left;
    flex: 1 1 0;
    visibility: hidden;
}

#home-footer-center {
    flex: 1 1 0;
}

#home-footer-right {
    font-size: 0.75rem;
    text-align: right;
    flex: 1 1 0;
    a {
        color: $color-steel-blue;
        text-decoration: none;

        &:visited {
            color: $color-steel-blue;
        }
    }
    
}