@import  '../../shared/styles/variable.scss';


.about {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }

}

.about-statistics {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: justify;
    margin-right:2rem;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-right:0;
    }
}

.about-title{
    color:$color-font-light-grey;
}

.statistics-container {
    display: flex;
    justify-self: center;
}

.statistics-frame {
    overflow: hidden;
    text-align:center;
    border-radius: 1rem;
    min-width: 10vw;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
    font-size: 0.85rem;
    
    &:nth-of-type(3) {
        margin-right: 0;
    }

    span {
        margin-left: 1rem;
        margin-right: 1rem;

    }
}

.statistics-icons {
    font-size: 2rem;
    opacity: 0.75;
    margin-bottom: 0.5rem;
}

.bubble-tea {
    height: 2.5rem;
    margin-bottom: 0;
}

.count-number {
    font-size : 1.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.expertise-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;

}

.expertise-frame {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;

    margin-right: 1rem;
    margin-bottom: 1rem;
    -webkit-box-shadow: 0px 0px 2rem -1rem rgb(192, 184, 184);
    -moz-box-shadow: 0px 0px 2rem -1rem rgb(192, 184, 184);
    -ms-box-shadow: 0px 0px 2rem -1rem rgb(192, 184, 184);
    -o-box-shadow: 0px 0px 2rem -1rem rgb(192, 184, 184);
    box-shadow: 0px 0px 2rem -1rem rgb(192, 184, 184);
    border-bottom: 2px solid $color-grey-bleu; 
    background: rgba(0,0,0,0.25);


    padding: 1.5rem;

    
    &:nth-of-type(3) {
        margin-right: 0;
    }

    @media screen and (max-height: 600px) {
        padding: 1rem;
    }

}


.expertise-icon {
    margin-top: -2.5rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}


.expertise-icon:before, .expertise-icon:after {
    content: ''; 
}

.expertise-icon:before {
    border-style: solid;
    border-width: 0 3rem 2rem 3rem;
}

.expertise-icon:after {
    border-style: solid;
    border-width: 2rem 3rem 0 3rem;
}

.blue-gray {
    background: $color-grey-bleu; 
}

.blue-gray:before {
    border-color: transparent transparent $color-grey-bleu transparent;
}

.blue-gray:after {
    border-color: $color-grey-bleu transparent transparent transparent; 
}


.expertise-icon svg {
    padding-top: 0.5rem;
}

.expertise-desc {
    margin-top: 1rem;
}

.profil-photo {
    
    @media screen and (max-height: 650px), screen and (max-width: 320px)  {
        display: none;
    }
}




.profil-photo figure {
    border: thin #c0c0c0 solid;
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    margin: auto;
}

.profil-photo figure img{
    opacity: 0.8;
}

.profil-photo figure figcaption {
    background-color: #222;
    color: #fff;
    font: italic smaller sans-serif;
    padding: 3px;
    text-align: center;
    opacity: 0.75;
}