@import  '../../shared/styles/variable.scss';

.skills-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

}
.skills-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.skills-title {
    color: $color-font-light-grey;
    margin-bottom: 0;
}

.information-icons {
    font-size: 1.5rem;

}

.skills-statistics {
    display: flex;
    flex: 0 0 80%; /* Let it fill the entire space horizontally */
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;


}

.slider-container-transition {
    transition: transform 0.7s ease-in-out;
}

.skills-category-container {
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
    display: none;
}



.skills-progess-container {
    color: $color-light-white;

    h3 {
        text-align: start;
        margin-bottom: 0.3rem;
    }

    span {
        position: relative;
        top: -1.6rem;
    }

}


.skills-category-name {
    color: $color-font-light-grey;
    margin-top: 2rem;
    margin-bottom: 0;
}
/*
@media (min-width: 600px) {
    .skills-progess-container {
        flex: 0 0 calc(45% - 1rem);
    }

    .skills-progess-container:nth-of-type(odd) {
        margin-right: 2rem;
    }

    .skills-category-name {
        margin-top: 4rem;
    }

}
*/

.skills-progess {
    height: 0.4rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #f2f3f7;
    overflow: visible; 
}

.skills-progess-bar {
    line-height: 1.2;
    font-weight: 600;
    text-align: right;
    height: 0.4rem;
    position: relative;

}

.skills-progess-bar:after {
    position: absolute;
    content: '';
    top: -0.3rem;
    right: 0;
    width: 1rem;
    height: 1rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.color-blue {
    background: $color-blue;
}

.color-blue:after {
    background: $color-blue;
}

.color-duck-blue {
    background: $color-duck-blue;
}

.color-duck-blue:after {
    background: $color-duck-blue;
}

.color-dark-blue {
    background: $color-dark-blue;
}

.color-dark-blue:after {
    background: $color-dark-blue;
}

.color-dark-green {
    background: $color-dark-green;
}

.color-dark-green:after {
    background: $color-dark-green;
}

.color-purple {
    background: $color-purple;
}

.color-purple:after {
    background: $color-purple;
}


.slider-nav {
    width: 2rem;
    height: 2rem;
    margin: 2rem 0.6rem;
    border-radius: 50%;
    z-index: 10;
    outline: 0.8rem solid  #36454f;
    outline-offset: -1rem;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    order: 4;
}

@-webkit-keyframes check {
    50% {
        outline-color:  $color-light-white;
    }
    100% {
        outline-color:  $color-light-white;
    }
}

@keyframes check {
    50% {
        outline-color:  $color-light-white;
    }
    100% {
        outline-color:  $color-light-white;
    }
}



.slider-nav:checked {
    -webkit-animation: check 0.4s linear forwards;
    animation: check 0.4s linear forwards;
  }

.slider-nav:checked:nth-of-type(1) ~ .skills-category-container:nth-of-type(1) {
    flex-direction: column;
    justify-content: flex-start;
    animation: scale-display 1s;
    display: inline-flex;
}

.slider-nav:checked:nth-of-type(2) ~ .skills-category-container:nth-of-type(2) {
    flex-direction: column;
    justify-content: center;
    animation: scale-display 1s;
    display: inline-flex;
}


.slider-nav:checked:nth-of-type(3) ~ .skills-category-container:nth-of-type(3) {
    flex-direction: column;
    justify-content: center;
    animation: scale-display 1s;
    display: inline-flex;
}


.slider-nav:checked:nth-of-type(4) ~ .skills-category-container:nth-of-type(4) {
    flex-direction: column;
    justify-content: center;
    animation: scale-display 1s;
    display: inline-flex;
}

.speech {
    margin-top: 4rem;
    margin-bottom: 2rem;
    order: -5;
}

.legend-timeline {
    width: 100%;
    order:5;
    display: flex;
    justify-content: center;

}


.legend-timeline-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 2rem;
    position: relative;
    margin-top: 8rem;
    width: 90%;

}

.legend-entry {
    width: 1.75rem;
    height: 1.75rem;
    background-color: $color-light-grey;
    position: relative;
    border-radius: 50%;
    
}


.legend-entry::before, .legend-entry::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-light-grey;
    width: 12vw;
    height: 0.4rem;
}

.legend-entry span::before {
    content: attr(data-info);
    top: -5rem;
    width: 5rem;
    transform: translateX(-0.3rem) rotateZ(-45deg);
    font-size: 1rem;
    text-indent: -0.8rem;
}

.legend-entry span::before, .legend-entry span::after {
    position: absolute;
}

.legend-entry  span::after {
    content: attr(data-percent);
    top: 2.5rem;
    transform: translateX(-50%);
    font-size: 1rem;
    width: 3rem;
}

@media (min-width: 851px) {
    .legend-entry::before {
      left: -2rem;
    }
  
    .legend-entry::after {
      right: -2rem;
    }
  }
  
@media (max-width: 850px) {
    .legend-entry  {
        width: 1rem;
        height: 1rem;
    }
    .legend-entry::before, .legend-entry::after {
        height:  0.4rem;
    }
    .legend-entry::before {
        left: calc(-4vw + 0.5rem);
    }
    .legend-entry::after {
        right: calc(-4vw + 0.5rem);
    }
}

.legend-entry:nth-of-type(1):after {
   display: none;
}

.legend-entry:nth-of-type(5):before, .legend-entry:nth-of-type(6):after {
    display: none;
 }

.legend-entry:nth-of-type(6):before, .legend-entry:nth-of-type(6):after {
    display: none;
 }
 


