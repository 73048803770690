
@import  '../../shared/styles/variable.scss';






.Experiences {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

}

.experiences-title {
    color: $color-font-light-grey;
}


.timeline-container {
    font-size: 0.75rem;

    position: relative;

    border-bottom-right-radius: 0.3rem;
    border-top-right-radius: 0.3rem;    
    background:$background-dark-gey;
    margin: 1.5rem auto;  
    letter-spacing: 0.5px;   
    font-weight: 100;  
    max-width: 85%; 
    opacity: 0.85;
    
    &:before {
        content: "";
        height: 99%;
        position: absolute;
        border: 3px solid $color-steel-blue;
        top: 0;
        left: calc(15%);
        right: auto;


    }
}



.timeline-entry {
    display: flex;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:last-of-type { 
        padding-bottom: 1.5rem;
        border: none;    

      }



    &:before {
        content: "";
        position: relative;
        box-shadow: 0 0 0 4px $color-steel-blue;    
        background: lighten($background-dark-gey,5%);    
        border-radius: 50%;  
        height: 0.75rem;
        width: 0.75rem;
        left:  calc(15% - 3px);
        top:0.5rem;
      }

}

@media screen and (max-width: 425px) {
    .timeline-container{
        width: 100%;
        max-width: 100%; 

    }

    .timeline-container::before {    
        border: 1px solid $color-steel-blue;
    }

    .timeline-entry::before {
        left:  calc(15% - 2px);
    }
}

@media screen and  (max-width: 1024px) {
    .timeline-entry::before {
        left:  calc(15% - 2px);
    }
}


.timeline-date {
    flex-basis: 15%;
    padding-right: 2rem;
}

.timeline-text {
    flex-basis: 85%;
    margin-left: 2rem;
    margin-right: 1rem;

    color: $color-light-white;
    text-align: left;
    opacity: 0.85;
    

    h3{
        margin: 0;
        font-weight: 500;
    }
}

.timeline-title-job {
    text-decoration: underline;
}

.timeline-title-client {
    -webkit-opacity: .4;
    -moz-opacity: .4;
    opacity: .4;
    -ms-filter: alpha(opacity=40);
    filter: alpha(opacity=40);
    font-size: 1rem;
    margin-left: 1rem;
}

.timeline-text-line {
    font-weight: 500;
    display: flex;

    span:nth-of-type(1) {
        margin-right: 1rem;
        text-decoration: underline;
    }

    @media screen and (max-width: 425px) {
        flex-direction: column;
        span:nth-of-type(2) {
            margin-top: 0.3rem;
        }
    }

}

